import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllStores: { args: ["payload"], meta: { async: true } },
    getStoreWithId: { args: ["payload"], meta: { async: true } },
    createStore: { args: ["payload"], meta: { async: true } },
    updateStore: { args: ["payload"], meta: { async: true } },
    deleteStore: { args: ["payload"], meta: { async: true } },
    setStore: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/store/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
