import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";

const apiNamespace = "transaction";

const getTransactionsWithOwnerIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS_WITH_OWNER_ID,
});
const getTransactionsWithServiceIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS_WITH_SERVICE_ID,
});
const getClientPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_POINTS,
});
const getTransactionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTION_WITH_ID,
});
const createGiftPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GIFT_POINTS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const scanCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.SCAN_CODE,
  showErrorMessage: false,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});


const addPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_POINTS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb:()=>{
   // window.location.reload();
  }
});
const getPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_POINTS,
});
export default [
  addPointsLogic,
  getPointsLogic,
  createGiftPointsLogic,
  getClientPointsLogic,
  scanCodeLogic,
  getTransactionsWithOwnerIdLogic,
  getTransactionsWithServiceIdLogic,
  getTransactionWithIdLogic,
];
