export default function (/**@type {ApisauceInstance} */ api) {
    const getAllStores = (paging) => api.get("/store/search", paging);
  
    const getStoreWithId = (id) => api.get("/store/find/" + id);
  
    const createStore = (payload) => api.post("/store/create", payload);
  
    const updateStore = (payload) => api.put("/store/edit", payload);
  
    const deleteStore = (id) => api.delete("/store/remove/" +id);
  
    return {
      store: {
        getAllStores,
        getStoreWithId,
        createStore,
        updateStore,
        deleteStore,
      },
    };
  }
  