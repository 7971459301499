export default function (/**@type {ApisauceInstance} */ api) {
  const getAllServices = (paging) => api.get("/Service/search", paging);
  const getServiceWithId = (id) => api.get("/Service/find/" + id);
  const getServiceTransactions= (payload) => api.get("/Service/service-transactions/" , payload);
  const getCurrentTenantCustomers= () => api.get("/Tenant/tenant-customers");

  const redeem=(payload)=>api.post("/redeem/user-voucher" , payload);
  const getServiceTypes = () => api.get("/Service/all-services");
  const getTypes = () => api.get("/Service/all-types");

  const getAvailableServices = (paging) => api.get("/Service/user-available-service",paging);
  const validateVoucher = (payload) => api.post("/redeem/voucher-inquiry",payload);
  const submitRedeemVoucher = (paging) => api.post("/Redeem/submit-voucher",paging);

  const getServiceOptionWithId=(id)=>api.get("/Service/find-option/" + id)
  const createService = (payload) =>api.post("/Service/create", payload);
  const createServiceOption = (payload) =>api.post("/Service/create-option", payload);
  const createVouchers= (payload) =>api.post("/Service/create-vouchers", payload);
  const updateService = (payload) => api.put("/Service/edit", payload);
  const updateServiceOption = (payload) => api.put("/Service/edit-option", payload);


  const deleteService = (id) => api.delete("/Service/remove/" + id);
 const deleteServiceOption = (id) => api.delete("/Service/remove-option/" + id);


  return {
    service: {
      validateVoucher,
      getCurrentTenantCustomers,
      submitRedeemVoucher,
      getAllServices,
      getTypes,
      getServiceWithId,
      getAvailableServices,
      getServiceTransactions,
      createService,
      createVouchers,
      updateService,
      deleteService,
      getServiceOptionWithId,
      createServiceOption,
      updateServiceOption,
      deleteServiceOption,
      getServiceTypes,
      redeem

    },
  };
}
