import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import layoutReducer from "./layout/reducer";
import navbarReducer from "./navbar/reducer";
// -------------------
import { authReducer } from "./auth/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "layout",
    "tenant",
    "store",
    "transaction",
    "city",
    "slap",
    "userManegment",
    "dashboard",
  ],
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      app: require("./app/reducer").reducer,
      auth: authReducer,
      layout: layoutReducer,
      navbar: navbarReducer,
      userManegment: require("./userManagement/reducer").reducer,
      settings: require("./setting/reducer").reducer,
      dashboard: require("./dashboard/reducer").reducer,
      tenant: require("./tenant/reducer").reducer,
      store: require("./store/reducer").reducer,
      city: require("./city/reducer").reducer,
      service: require("./service/reducer").reducer,
      transaction: require("./transaction/reducer").reducer,



    })
  );
}
