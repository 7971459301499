import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("transactions", { metadata: undefined }, []),
  ...defaultState("ownerTransactions", { metadata: undefined }, []),
  ...defaultState("serviceTransactions", { metadata: undefined }, []),
  ...defaultState("gift", { metadata: undefined }, {}),
  ...defaultState("points", { metadata: undefined }, {}),
  ...defaultState("updatedPoints", { metadata: undefined }, 0),
  ...defaultState("transaction", { metadata: undefined }, {}),
};
const createGiftPointsSuccess = (state, draft, payload) => {
  const transactions = state.transactions || [];
  draft.transactions = [...transactions, payload];
  draft.updatedPoints = payload?.balance;
  draft.openDialog = false;
};
const addPointsSuccess = (state, draft, payload) => {
 // debugger
  let tenantBalance = state.points.tenantWallet.balance;

  let mainWallet = state.destination.mainWallet;
  draft.points.tenantWallet.balance = mainWallet
    ? tenantBalance + payload.amount
    : tenantBalance - payload.amount;
 // const scibWalletTransactions = state.ownerTransactions || [];
 // draft.ownerTransactions = [...scibWalletTransactions, payload];
  draft.updatedPoints = payload?.amount;
  draft.openDialog = false;
};
const setDestination = (state, draft, payload) => {
  // debugger
  draft.destination.destId = payload?.destId;
  draft.destination.mainWallet = payload?.mainWallet;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};
const resetDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = false;
};


const setItem = (state, draft, payload) => {
  const { item, data } = payload;
  draft[item] = data;
};

export const reducer = createReducer(initialState, {
 
  ...bindReducers(ActionTypes, {
    action: "getTransactionsWithOwnerId",
    stateName: "ownerTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addPoints",
    stateName: "addedPoints",
    async: true,
    isPaginated: false,
   successCb: addPointsSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPoints",
    stateName: "points",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionsWithServiceId",
    stateName: "serviceTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionWithId",
    stateName: "transaction",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGiftPoints",
    stateName: "gift",
    async: true,
    isPaginated: false,
    successCb: createGiftPointsSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "scanCode",
    stateName: "scannedCode",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "setItem",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
  ...bindReducers(ActionTypes, {
    action: "getClientPoints",
    stateName: "points",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDeleteDialog",
    stateName: "resetDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: resetDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "setDestination",
    stateName: "destination",
    async: false,
    isPaginated: false,
    successCb: setDestination,
  }),
});
