import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
const apiNamespace = "store";

const getAllStoresLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_STORES,
});
const getStoreWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_STORE_WITH_ID,
});
const createStoreLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_STORE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/store/list"));
  }
});
const updateStoreLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_STORE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/store/list"));
}
});
const deleteStoreLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_STORE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
    getAllStoresLogic,
    getStoreWithIdLogic,
    createStoreLogic,
    updateStoreLogic,
    deleteStoreLogic,
];
