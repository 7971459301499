export default function (/**@type {ApisauceInstance} */ api) {
    const getUserTransactions = (id) =>
      api.get("/wallet-management/user-transactions/", id);
  
    const getTransactionsWithServiceId = (id) =>
      api.get("/wallet-management/service-transactions/", id);
  
    const getTransactionsWithOwnerId = (id) =>
      api.get("/wallet-management/owner-transactions/", id);
    const getClientPoints = (id) =>
      api.get("/wallet-management/client-points/" + id);
    const getTransactionWithId = (id) =>
      api.get("/wallet-management/transaction/" + id);
  
    const createGiftPoints = (payload) =>
      api.post("/wallet-management/create-gift/", payload);
      const addPoints = (paging) => api.post("/wallet-management/add-tenant-points", paging);
      const getPoints = (id) => api.get("/wallet-management/Tenant-points/"+id);
  
    const scanCode = (payload) =>
      api.post("/wallet-management/scan-for-client/", payload);
  
    return {
      transaction: {
        getUserTransactions,
        addPoints,
        getPoints,
        getTransactionsWithServiceId,
        getTransactionsWithOwnerId,
        getTransactionWithId,
        createGiftPoints,
        getClientPoints,
        scanCode,
      },
    };
  }
  