import logic from "src/utils/genLogic";
import actions, { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
const apiNamespace = "Cities";




const getAllCountriesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_COUNTRIES,
});
const createCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_COUNTRY,

});
const editCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_COUNTRY,
});
const deleteCountryLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_COUNTRY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,

});
const getAllCitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CITIES,
});
const getCityWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CITY_WITH_ID,
});
const createCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CITY,
  successCb: (dispatch) => {
    dispatch(push("/cities/list"));
  },
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const editCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteCityLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CITY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

const getAllGovernoratesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_GOVERNORATES,
});
const createGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GOVERNORATE,
  successCb: (dispatch) => {
    dispatch(push("/governorates/list"));
  },
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const editGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_GOVERNORATE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_GOVERNORATE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllCountriesLogic,
  createCountryLogic,
  editCountryLogic,
  deleteCountryLogic,
  
  getAllCitiesLogic,
  getCityWithIdLogic,
  createCityLogic,
  editCityLogic,
  deleteCityLogic,

  getAllGovernoratesLogic,
  createGovernorateLogic,
  editGovernorateLogic,
  deleteGovernorateLogic,
];
