import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    addPoints: { args: ["payload"], meta: { async: true } },
    getPoints: { args: ["payload"], meta: { async: true } },
    setDestination: { args: ["payload"], meta: { async: false } },
    getTransactionWithId: { args: ["payload"], meta: { async: true } },
    createGiftPoints: { args: ["payload"], meta: { async: true } },
    getClientPoints: { args: ["payload"], meta: { async: true } },
    getTransactionsWithOwnerId: { args: ["payload"], meta: { async: true } },
    getTransactionsWithServiceId: { args: ["payload"], meta: { async: true } },
    scanCode: { args: ["payload"], meta: { async: true } },
    setItem: { args: ["payload"], meta: { async: false } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    resetDeleteDialog: { args: [], meta: { async: false } },

  },
  {
    prefix: "@app/transaction/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
