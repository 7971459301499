import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import usersLogic from "./userManagement/logic";
import settingLogic from "./setting/logic";
import tenantLogic from "./tenant/logic";
import storeLogic from "./store/logic";
import cityLogic from "./city/logic";
import dashboardLogic from "./dashboard/logic";
import serviceLogic from "./service/logic";
import transactionLogic from "./transaction/logic";






export default [
  ...appLogic,
  ...authLogic,
  ...usersLogic,
  ...settingLogic,
  ...dashboardLogic,
  ...tenantLogic,
  ...storeLogic,
 ...cityLogic,
...serviceLogic,
...transactionLogic
];
