import tenantActions, { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";

const apiNamespace = "tenant";

const getAllTenantsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_TENANTS,
});
const getTenantWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TENANT_WITH_ID,
});
const createTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_TENANT,
  successCb: (dispatch, payload) => {
    const tenantId = payload.id;
    window.location.replace(`/tenants/details/${tenantId}`);
  },
});
const updateTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_TENANT,
});
const deleteTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_TENANT,
});
//
const createTenantConfigurationLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_TENANT_CONFIGURATION,
});

const createTenantContactLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_TENANT_CONTACT,
  successCb: (dispatch) => dispatch(tenantActions.resetDialog()),
});
const updateTenantConfigurationLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_TENANT_CONFIGURATION,
});

const updateTenantContactLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_TENANT_CONTACT,
  successCb: (dispatch) => dispatch(tenantActions.resetDialog()),
});

export default [
  getAllTenantsLogic,
  getTenantWithIdLogic,
  createTenantLogic,
  updateTenantLogic,
  deleteTenantLogic,
  //
  createTenantConfigurationLogic,
  createTenantContactLogic,
  updateTenantConfigurationLogic,
  updateTenantContactLogic,
];
