import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {

    createCountry: { args: ["payload"], meta: { async: true } },
    editCountry: { args: ["payload"], meta: { async: true } },
    deleteCountry: { args: ["payload"], meta: { async: true } },
    getCountryWithId: { args: ["payload"], meta: { async: true } },
    getAllCountries: { args: ["payload"], meta: { async: true } },
    getAllCities: { args: ["payload"], meta: { async: true } },
    getCityWithId: { args: ["payload"], meta: { async: true } },
    createCity: { args: ["payload"], meta: { async: true } },
    editCity: { args: ["payload"], meta: { async: true } },
    deleteCity: { args: ["payload"], meta: { async: true } },
    getAllGovernorates: { args: ["payload"], meta: { async: true } },
    getGovernorateWithId: { args: ["payload"], meta: { async: true } },
    createGovernorate: { args: ["payload"], meta: { async: true } },
    editGovernorate: { args: ["payload"], meta: { async: true } },
    deleteGovernorate: { args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Cities/",
  }
);
export const ActionTypes = Types;
export default Creators;
