import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getSettings: { args: [], meta: { async: true } },
    editSetting:{  args: ["payload"], meta: { async: true } },
    editAllSettings:{  args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/settings/"
  }
);
export const ActionTypes = Types;
export default Creators;