import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("stores", { metadata: undefined }, []),
  ...defaultState("store", { metadata: undefined }, {}),
};

const storeDeleteSuccess = (state, draft, payload) => {
   // debugger;
    const stores = state.stores || [];
    draft.stores = stores.filter((a) => a.id != payload.id);
    draft.openDeleteDialog = false;
  };
  const setStore=(state,draft,payload)=>{
    draft.store=undefined;
  }
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.user_errors = [];
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllStores",
    stateName: "stores",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getStoreWithId",
    stateName: "store",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createStore",
    stateName: "store",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateStore",
    stateName: "store",
    async: true,
    isPaginated: false,
    // successCb: updateStoreSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteStore",
    stateName: "Store",
    async: true,
    isPaginated: false,
    successCb:storeDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setStore",
    stateName: "Store",
    async: false,
    isPaginated: false,
    successCb:setStore,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
//   ...bindReducers(ActionTypes, {
//     action: "showDialog",
//     stateName: "showDialog",
//     async: false,
//     isPaginated: true,
//     successCb: showDialog,
//   }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
});
