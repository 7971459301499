import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllServices: { args: ["payload"], meta: { async: true } },
    getTypes: { args: ["payload"], meta: { async: true } },

    getServiceTypes: { args: ["payload"], meta: { async: true } },
    getCurrentTenantCustomers: { args: [""], meta: { async: true } },

    getAvailableServices: { args: ["payload"], meta: { async: true } },
    redeem: { args: ["payload"], meta: { async: true } },
    getServiceOptionWithId: { args: ["payload"], meta: { async: true } },
    getServiceWithId: { args: ["payload"], meta: { async: true } },
    getServiceTransactions: { args: ["payload"], meta: { async: true } },
    validateVoucher: { args: ["payload"], meta: { async: true } },

    submitRedeemVoucher: { args: ["payload"], meta: { async: true } },

    createService: { args: ["payload"], meta: { async: true } },
    createServiceOption: { args: ["payload"], meta: { async: true } },
    createVouchers: { args: ["payload"], meta: { async: true } },


    updateService: { args: ["payload"], meta: { async: true } },
    updateServiceOption: { args: ["payload"], meta: { async: true } },

    deleteService: { args: ["payload"], meta: { async: true } },
    getServiceOptions: { args: ["payload"], meta: { async: true } },
    deleteServiceOption: { args: ["payload"], meta: { async: true } },
    setOption: { args: ["payload"], meta: { async: false } },
    setService: { args: ["payload"], meta: { async: false } },
    
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    setSubmitRedeem: { args: ["payload"], meta: { async: false } },
    setValidateVoucher: { args: ["payload"], meta: { async: false } },

    setRedeem: { args: ["payload"], meta: { async: false } },

  },
  {
    prefix: "@app/service/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
