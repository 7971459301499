export default function (/**@type {ApisauceInstance} */ api) {


    const getAllCountries = (paging) => api.get("/Country/search",paging);
    const getCountryWithId=(id)=>api.get("/Country/find/" + id)
    const createCountry = (payload) => api.post("/Country/create", payload);
    const editCountry = (payload) => api.put("/Country/edit", payload);
    const deleteCountry = (id) => api.delete("/Country/remove/"+id);
    
    const getAllCities = (paging) => api.get("/City/search",paging);
    const createCity = (payload) => api.post("/City/create", payload);
    const getCityWithId=(id)=>api.get("/City/find/" + id)
    const editCity = ({payload}) => api.put("/City/edit", payload);
    const deleteCity = (id) => api.delete("/City/remove/"+id);
  
    const getAllGovernorates = (paging) => api.get("/governorate/search",paging);
    const createGovernorate = (payload) => api.post("/governorate/create", payload);
    const getGovernorateWithId=(id)=>api.get("/governorate/find/" + id)
    const editGovernorate = ({payload}) => api.put("/governorate/edit", payload);
    const deleteGovernorate = (id) => api.delete("/governorate/remove/"+id);
  
    return {
      Cities: {
        getCountryWithId,
        getAllCountries,
        createCountry,
        editCountry,
        deleteCountry,

        getAllCities,
        createCity,
        getCityWithId,
        editCity,
        deleteCity,

        getAllGovernorates,
        createGovernorate,
        getGovernorateWithId,
        editGovernorate,
        deleteGovernorate,
      },
    };
  }
  
  