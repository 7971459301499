export default function (/**@type {ApisauceInstance} */ api) {
    const getSettings = () => api.get("Setting/settings");
    const editSetting = ({ payload, id }) => api.put(`Setting/edit-setting/${id}`, payload);
    const editAllSettings = (payload) => api.put(`Setting/edit-all-setting`, payload);
   
  
    return {
      settings: {
        getSettings,
        editSetting,
        editAllSettings
      }
    };
  }
  